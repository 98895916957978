import { Component } from '@angular/core';
import { AnalyticsService } from './services/analytics.service';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Reite Store';

  supportLanguages =  ['es', 'en'];

  constructor(
    private analytics: AnalyticsService,
    private translateService: TranslateService,
    private updates: SwUpdate,
    private meta: Meta
  ) {
    const appVersion = 'v1.1.0'; // Set this dynamically during build
    const cachedVersion = localStorage.getItem('appVersion');

    if (cachedVersion !== appVersion) {
      localStorage.setItem('appVersion', appVersion);
      console.log(`Clearing cache, new version ${appVersion} detected`);
      window.location.reload();
    }
    updates.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        // Optionally, you can show a notification or prompt users before reloading
        if (confirm("Nueva versión disponible. ¿Desea recargar la página para actualizar?")) {
          window.location.reload();
        }
      }
    });
    this.analytics.logEvent('app_open');
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('es');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang? browserLang : 'es');
    const themeColor = environment.themeColor;
    this.meta.updateTag({ content: themeColor }, 'name=theme-color');
  }

}
